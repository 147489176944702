.header {
    display: flex;
    height: 50px;
    background: #1f1f30 !important;
    font-weight: 600;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    width: 100%;
  }
  
  .header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 60px;
  }
  
  .cs-logo {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    right: 26px;
    top: 3px;
  }
  
  .cs-logo svg {
    fill: currentColor;
    display: block;
    width: 40px;
  }

  .maincontainer_heading{
    font-size: 32px;
    font-weight: 400;
    padding: 87px 352px 3px 193px;
    font-family: system-ui;
    font-style: normal;
  }

  .maincontainer_message{
    font-size: 16px;
    padding: 3px 0px 6px 192px;
    color: var(--text-dark, #22242D);
    font-family: system-ui;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .maincontainer_engagement_link{
   color: rgb(65, 113, 158);
  }
  
  @media screen and (max-width: 768px) {
    .header{
        width: 530px;
    }

    .maincontainer_message{
        font-size: 21px;
        padding: 0px 0px 0px 81px;
    }

    .maincontainer_heading{
        padding: 86px 352px 3px 80px;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .header{
        width: revert-layer;
    }
    .maincontainer_message{
        font-size: 21px;
       padding: 2px 0px 6px 73px;
    }
    .maincontainer_heading{
        padding: 85px 352px 3px 76px;
    }
  }