body{
  background: #eff1f4;
  font-feature-settings: "kern","liga","calt","case","ss03","cv03","cv04";
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  margin:0 
}

.clob-header {
    height: 60px;
    background: #1f1f30;
  }
  
  .clob-header__home-icon {
    background: #076cdf;
    padding: 0 20px !important;
    height: inherit;
    line-height: 60px;
  }
  
  .clob-header__home-icon>a {
    color: #f7f7f8;
  }
  
  .clob-header__home-icon>a:hover {
    color: #f7f7f8;
  }
  
  .clob-header__sub-heading {
    display: none;
    color: #f7f7f8;
  }
  
  .clob-header__hamburger_close,
  .l-header__hamburger_open {
    transition: all 500ms ease-in-out;
  }
  
  .clob-header__hamburger_close {
    color: #f7f7f8;
    background: #1f1f30;
  }
  
  .clob-header__hamburger_open {
    color: #1f1f30;
    background: #f7f7f8;
  }
  
  .dentsu-navigation__section>ul>li:not(:last-child) {
    border-bottom: 1px solid #E5E5E9;
  }
  
  .clob-header__right-content {
    display: flex;
    align-items: center;
  }
  
  .clob-header__right-content--hide {
    display: none;
  }
  
  @media (min-width: 1200px) {
    .clob-header__sub-heading {
      display: block;
      font-size: 14px;
      line-height: 22px;
      opacity: 0.8;
      font-weight: 400;
    }
  
    .clob-header__right-content--hide {
      display: flex;
      align-items: center;
    }
  
    .clob-header__hamburger_close,
    .l-header__hamburger_open {
      display: none;
    }
  }